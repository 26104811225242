<template>
  <v-card
    class="d-flex flex-column"
    min-width="200"
    :loading="isLoadingImage"
    style="flex: 1; height: fit-content; width: 100%"
    elevation="0"
    outlined
    @click="seeProfile"
  >
    <div
      class="d-flex flex-column ma-4"
      style="height: 350px"
    >
      <div
        class="d-flex flex mx-0"
        style="flex: 1; overflow: hidden; border-radius: 4px"
      >
        <v-img
          class="d-flex flex-column rounded"
          data-dd-privacy="hidden"
          width="100%"
          aspect-ratio="1/1"
          :src="profileImage"
        />
      </div>
      <div
        class="d-flex flex-column mt-2"
        style="display: flex; flex: 0; justify-content: center;"
      >
        <v-tooltip
          bottom
          left
          open-delay="600"
          open-on-hover
        >
          <template v-slot:activator="{ on, attrs }">
            <strong
              class="text-truncate subtitle-1 text-sm-h6 font-weight-bold textBlack--text"
              v-bind="attrs"
              v-on="on"
            >
              {{ profileName }}
            </strong>
          </template>
          <span>{{ profileName }}</span>
        </v-tooltip>
        <div class="d-flex flex-wrap mb-4">
          <tag
            v-for="(tag, idx) in previewTags"
            :key="`${tag.id}-${idx}`"
            class="pa-1"
            small
            :tag-id="tag.id"
          />
          <div
            v-if="tags.length > 2"
            class="d-flex flex-column ml-2"
            style="flex: 1"
          >
            <v-spacer />
            <square-button
              icon-name="mdi-tag-multiple-outline"
              outlined
              color="transparentBackground"
              style="margin-top: auto; z-index: 2"
              icon-color="neutral"
            >
              <v-card max-width="300">
                <v-card-title>
                  {{ $t('deconve.tags') }}
                </v-card-title>
                <v-card-text>
                  <div class="d-flex flex-wrap mb-4">
                    <tag
                      v-for="(tag, idx) in tags"
                      :key="`${tag.id}-${idx}`"
                      class="pa-1"
                      :tag-id="tag.id"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </square-button>
          </div>
        </div>
      </div>
      <div class="d-flex justify-space-between">
        <div class="d-flex align-center">
          <v-icon
            color="neutral"
            class="mr-1"
            :size="$vuetify.breakpoint.mobile? 16: 20"
          >
            {{ icons.calendarRange }}
          </v-icon>
          <span
            v-if="createdAt"
            class="caption text-sm-body-2 neutral--text"
          >
            {{ createdAt | moment('DD/MM/YYYY') }}
          </span>
        </div>

        <div class="d-flex align-center">
          <v-icon
            color="neutral"
            class="mr-1"
            :size="$vuetify.breakpoint.mobile? 16: 20"
          >
            {{ icons.superProfileIcon }}
          </v-icon>
          <span
            class="caption text-sm-body-2 neutral--text"
          >
            {{ numberOfPeopleOnProfile }}
          </span>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import { downloadImage } from '@/api';
import { mdiCalendarRange, mdiAccountGroup } from '@mdi/js';

import Tag from '@/components/Tag.vue';
import SquareButton from '@/components/SquareButton.vue';

export default {
  name: 'SuperProfilePreview',
  components: {
    Tag,
    SquareButton,
  },
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      profileImage: '',
      isLoadingImage: false,
      icons: {
        calendarRange: mdiCalendarRange,
        superProfileIcon: mdiAccountGroup,
      },
    };
  },
  computed: {
    ...mapGetters({
      getSuperProfile: 'faceid/getSuperProfile',
    }),
    superProfile() {
      return this.getSuperProfile(this.id);
    },
    profileName() {
      return this.superProfile?.name || '';
    },
    tags() {
      return this.superProfile?.tags || [];
    },
    previewTags() {
      return this.tags.slice(0, 2);
    },
    createdAt() {
      return this.superProfile?.created_at || '';
    },
    numberOfPeopleOnProfile() {
      return this.superProfile?.people.length || 0;
    },
  },
  created() {
    this.isLoadingImage = true;

    this.fetchSuperProfile(this.id).then(() => {
      this.downloadProfileImage();
    }).catch(() => {
      this.isLoadingImage = false;
    });
  },

  methods: {
    ...mapActions({
      fetchSuperProfile: 'faceid/fetchSuperProfile',
    }),
    downloadProfileImage() {
      if (this.superProfile) {
        const { profile_image_url: profileImageUrl } = this.superProfile;

        if (!profileImageUrl) {
          this.isLoadingImage = false;
          return;
        }

        downloadImage(profileImageUrl).then((image) => {
          this.profileImage = image;
          this.isLoadingImage = false;
        });
      }
    },
    seeProfile() {
      if (!this.isLoadingImage) {
        this.$router.push({ name: 'faceid-super-profile', params: { superProfileId: this.id } });
      }
    },
  },
};
</script>
